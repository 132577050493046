
import CategoriesListItem from '~/components/elements/CategoriesListItem';
export default {
  name: 'CategoriesList',
  components: {CategoriesListItem},
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    mqIsMobile() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile';
    },
    sublistPost() {
      return this.mqIsMobile ? 'top' : 'right'
    },
  },
  methods: {
    isSublist(item) {
      return item?.categories?.length
    },
  },
}
