
export default {
  name: 'CategoriesListItem',
  props: {
    code: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    sublist: {
      type: Boolean,
      default: false,
    },
  },
}
