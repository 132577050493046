
import CustomImage from '~/components/elements/CustomImage';
import CustomImagePicture from '~/components/elements/CustomImage/Picture';
import CategoriesList from '~/components/elements/CategoriesList';

export default {
  name: 'Categories',
  components: {
    CategoriesList,
    CustomImagePicture,
    CustomImage,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    titleCenter: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    mqIsMobile() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile' || this.$mq === 'tablet' || this.$mq === 'laptop';
    },
    imageSizes() {
      return this.mqIsMobile ? [144, 144] : [182, 182];
    },
  },
};
